@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap');

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100vh;
}
body {
  height: calc(100vh - 1rem);
}

body {
  background: rgb(233, 233, 233);
  &,
  ::placeholder {
    font-family: 'Roboto Mono', 'Calibri';
  }
  padding: 0.5rem;
  box-sizing: border-box;
  margin: 0;
}

#editorjs {
  background-color: white;
  min-height: calc(100% - 3rem);
  padding: {
    top: 1rem;
    bottom: 1rem;
  }
}

.codex-editor__redactor {
  padding-bottom: 0 !important;
}

textarea {
  font-size: 11pt;
  width: 100%;
  border: none;
}

.list-element {
  padding: 0.25rem;
}

.tag,
.date {
  font-weight: bold;
}

.date {
  text-decoration: underline;
}

.ce-block {
  // Disable built-in editor.js animation
  animation: none;
}

// Hacky way to disable default paragraph tool
// https://github.com/codex-team/editor.js/discussions/1897#discussioncomment-2788235
[data-item-name='paragraph'] {
  display: none;
}

div.ce-block__content,
div.ce-toolbar__content {
  max-width: calc(100% - 1rem);
  margin-left: 0.5rem;
}

div[contenteditable] {
  // Preserve line breaks in blocks
  white-space: pre-wrap;
  overflow-wrap: break-word;
  // Remove default browser's outline
  outline: none;
}

.tags-picker {
  position: fixed;
  background-color: lightgrey;
  z-index: 999;
  list-style-type: none;
  padding: 0;
  li {
    &.active {
      background-color: DodgerBlue;
      color: white;
      cursor: pointer;
    }
  }
}

.tag-completed {
  text-decoration: line-through;
}

.ce-toolbar {
  display: none !important;
}

.ball-gif {
  width: 18px;
  margin-left: 0.5rem;
}

#filter-input {
  width: 100%;
  display: block;
  box-sizing: border-box;
  height: 2rem;
  padding-left: 0.5rem;
}
